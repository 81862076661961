<template>
  <div class="box">
    <el-form ref="form" :model="form" class="form">
      <div class="title "></div>
      <div style="display: flex;margin-bottom: 22rem">
        <div style="width: 188rem;min-width: 188rem;text-align: right;color: #666;line-height: 40rem">测评名称</div>
        <div style="padding-left: 28rem;line-height: 40rem;color: #666">{{ dataCont.name }}</div>
      </div>
      <el-form-item label="应用角色" >
        <el-input v-model="dataCont.role_text" readonly placeholder="请输入"></el-input>
      </el-form-item>
      <div v-if="false" style="display: flex;margin-bottom: 22rem">
        <div style="width: 188rem;text-align: right;color: #666;line-height: 40rem">测试范围</div>
        <div style="padding-left: 20rem">
          <el-tree :default-expand-all="true" :data="dataCont.range_tree" :props="{children: 'children',label: 'label'}" ref="tree" ></el-tree>
        </div>
      </div>
      <div style="display: flex;margin-bottom: 22rem">
        <div style="width: 188rem;min-width: 188rem;text-align: right;color: #666;line-height: 40rem">选择问卷</div>
        <div style="padding-left: 28rem;line-height: 40rem;color: #666">{{ dataCont.paper }}</div>
      </div>
      <el-form-item label="选择开始时间">
        <el-input v-model="dataCont.start_at" readonly placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="选择结束时间">
        <el-input v-model="dataCont.end_at" readonly placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item v-if="dataCont.role === 1" label="学生端展示最低版本">
        <el-input v-model="dataCont.version_text" readonly placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="展示图片">
        <el-image v-if="dataCont.image_url"
            style="width: 100rem; height: 100rem;margin-left: 20rem"
            :src="dataCont.image_url"
            :preview-src-list="[dataCont.image_url]"></el-image>
      </el-form-item>
    </el-form>

    <div class="footer-button">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>

export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  data() {
    return {
      dataCont: {},
      form: {}
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$_axios2('/api/evaluation/index/' + this.$route.query.id).then(res => {
        // console.log(res)
        if (res.data.status === 0) {
          this.dataCont = res.data.data
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  width: 150rem;
}

::v-deep .is-uploaded .el-upload {
  display: none;
}

::v-deep .el-input__inner{
  border-color: transparent !important;
}

::v-deep .el-form-item__label{
  position: relative;
  z-index: 1;
}

::v-deep .el-tree__empty-text{
  min-width: 80rem !important;
  position: static;
}
::v-deep .el-tree__empty-block{
  min-height: 0 !important;
  transform: translateY(3rem);
}

.el-tree{
  margin-top: 7rem;
}

.box {
  .form {
    font-size: 14rem;
    margin: 0 0 20rem 50rem;

    ::v-deep .el-form-item__error {
      margin-left: 80rem;
    }

    .title {
      font-weight: bold;
      font-size: 16rem;
      padding-top: 35rem;
      margin-bottom: 35rem;
    }

    .el-form-item {
      margin-left: 50rem;
    }

    .subject {
      display: flex;
      align-items: center;

      ::v-deep .el-form-item__error {
        margin-left: 0;
      }

      //padding: 15rem 0rem;
      //justify-content: space-around;
      margin-bottom: 15rem;

      .item_box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 15rem 10rem;
        flex: 1;
      }

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10rem;

        > span {
          margin-right: 15rem;
        }

        .btn {
          border-radius: 50%;
          width: 30rem;
          height: 30rem;
          text-align: center;
          line-height: 30rem;
          cursor: pointer;
          margin-left: 15rem;
          border: 1rem solid #666666;
        }
      }

      .item.btns {
        width: 100rem;
        justify-content: flex-start;
        text-align: left;

        .btn {
          display: inline-block;
        }
      }

      .el-form-item {
        margin: 0;
      }

    }

    @media only screen and (max-width: 1399rem) {
      .subject {
        .item {
          display: block;
          text-align: center;

          > span {
            display: block;
            margin-right: 0;
            margin-bottom: 15rem;
          }

          ::v-deep .el-input {
            width: 180rem !important;
          }
        }

        .btns {
          width: 130rem;
          justify-content: flex-start;
          text-align: left;

          .btn {
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>
